import React from 'react'
import * as S from './styles'

const PageSlider = ({ show, background = "#fff", children }: {show: boolean; background?: string; children: React.ReactNode}) => {
  return (
    <S.PageSlider show={show} background={background}>
      {children}
    </S.PageSlider>
  )
}

export default PageSlider
