
import tesouroEduca2025 from '../data/tesouro-educa-2035.json'
import tesouroIpca2029 from '../data/tesouro-ipca-2029.json'
import tesouroSelic2027 from '../data/tesouro-selic-2027.json'
import tesouroRenda2045 from '../data/tesouro-renda-2045.json'
import { useState } from 'react'

export enum InvestmentType {
  tesouroEduca2025 = 'tesouroEduca2025',
  tesouroIpca2029 = 'tesouroIpca2029',
  tesouroRenda2045 = 'tesouroRenda2045',
  tesouroSelic2027 = 'tesouroSelic2027',
}

export const useInvestmentData = () => {
  const [ investment, setInvestment ] = useState<InvestmentType | null>(null)
  const investments = {
    tesouroEduca2025: tesouroEduca2025,
    tesouroIpca2029: tesouroIpca2029,
    tesouroRenda2045: tesouroRenda2045,
    tesouroSelic2027: tesouroSelic2027,
  }

  if (!investment) {
    return { data: null, setInvestment }
  }

  return { data: investments[investment], setInvestment }
}
