import { useEffect, useState } from "react"

export type InterBridge = typeof import("@interco/inter-webview-bridge")

export const useInterBridgeInstance = () => {
  const [ iWb, setIWb ] = useState<InterBridge>()
  useEffect(() => {
    import('@interco/inter-webview-bridge').then((interBridge: InterBridge) => {
      setIWb(interBridge)

      const bridgeInstance = interBridge.default.getInstance()
      bridgeInstance.addWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
      return () => bridgeInstance.removeWebviewEventListener(interBridge.WbSessionActions.GO_BACK, interBridge.interWbNavigate.requestGoBack)
    })
  }, [])

  return iWb
}
