import { grayscale } from "src/styles/colors"
import styled from "styled-components"

export const InvestmentsDisplay = styled.section`
  .investments__title {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0.5rem;
    color: ${grayscale[500]};
  }

  .investments__body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: ${grayscale[400]};
    margin-bottom: 1.5rem;
  }

  .investments-list__title {
    font-family: 'Sora', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: #000;
    margin-bottom: 0.5rem;
  }

  .investments-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1.5rem;
  }

  .investments-list__item {
    display: grid;
    grid-template-columns: 24px minmax(272px, 3fr);
    gap: 1rem;
    padding: 1rem 0;

    &>div {
      align-self: center;
    }

    svg {
      width: 24px;
      height: 24px;
      align-self: center;
      justify-self: start;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${grayscale[200]};
    }
  }

  .investments-list__item__title {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: ${grayscale[500]};
    margin-bottom: 0.25rem;
  }

  .investments-list__item__body {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${grayscale[400]};
    margin-bottom: 0;
  }

  .investments__link {
    text-decoration: none;
    display: block;
    width: 100%;
    height: fit-content;
  }
`
