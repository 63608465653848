import { gray, grayscale } from "src/styles/colors"
import styled from "styled-components"

export const ConstruaSeuTesoursoFAQ = styled.section`
  padding: 1.5rem;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .faq__body,
  .terms-and-conditions-btn {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${grayscale[400]};
  }

  .faq__body p {
    margin-bottom: 1rem;
  }

  .terms-and-conditions-btn {
    .terms-and-conditions-btn__text {
      flex-grow: 1;
      text-align: left;
      padding-left: 1rem;
    }
    background-color: #fff;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${grayscale[500]};
    font-weight: 600;
    border: 1px solid ${gray[200]};
    padding: 1rem;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`
