import { runWhen } from "src/shared/runWhen"
import { ACESSO_TAG, FLUXO_TAG, PRODUCT_NAME, TEXT_CONTENT } from "../_constants"
import { InterBridge } from "../hooks/_useInterBridgeInstance"
import { addEventToWindowDataLayer } from "src/hooks/useDataLayer/dataLayerBody"

export const tagPageLoad = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  return runWhen(ternaryCondition, () => (iWb?.interWbSession.requestAnalytics(ACESSO_TAG, {
    screen: TEXT_CONTENT.heading,
    content_action: "Dado carregado",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  })), () => { return null })
}

export const tagCardClick = (iWb: InterBridge | undefined, ternaryCondition: boolean, title: string) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "bottomsheet",
    content_action: "Toque",
    action_id: title,
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  }))

  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: TEXT_CONTENT.heading,
      section_name: title,
      element_action: 'click button',
      element_name: 'Conhecer',
      redirect_url: null,
    })
  )

  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagCtaClick = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: TEXT_CONTENT.cta,
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  }))
  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: TEXT_CONTENT.heading,
      element_action: 'click button',
      element_name: TEXT_CONTENT.cta,
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagVideoClick = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "video",
    content_action: "Toque",
    action_id:
      "4 passos práticos para aprender a investir no Tesouro Direto.",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  }))

  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: TEXT_CONTENT.heading,
      element_action: 'click video',
      element_name: '4 passos práticos para aprender a investir no Tesouro Direto.',
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagHelpClick = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: "Ajuda",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  }))
  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: "Construa seu Tesouro",
      element_action: 'click button',
      element_name: 'Ajuda',
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagBackClick = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: TEXT_CONTENT.heading,
    ref_type: "botao",
    content_action: "Toque",
    action_id: "Voltar",
    adjust_event_token: "0",
    product: PRODUCT_NAME,
  }))
  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: TEXT_CONTENT.heading,
      element_action: 'click button',
      element_name: 'Voltar',
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagAccordionClick = (iWb: InterBridge | undefined, question: string, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: 'Construa seu Tesouro',
    ref_type: 'botao',
    content_action: 'Toque',
    action_id: question,
    adjust_event_token: '0',
  }))
  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: "Construa seu tesouro",
      section_name: "Construa seu tesouro FAQ",
      element_action: 'click button',
      element_name: question,
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}

export const tagTermsAndConditionsClick = (iWb: InterBridge | undefined, ternaryCondition: boolean) => {
  const mobileTagging = () => (iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
    screen: 'Construa seu Tesouro',
    ref_type: 'botao',
    content_action: 'Toque',
    action_id: 'Termos e Condições',
    adjust_event_token: '0',
  }))

  const desktopTagging = () => (
    addEventToWindowDataLayer({
      section: "Construa seu tesouro",
      section_name: "Construa seu tesouro FAQ",
      element_action: 'click button',
      element_name: 'Termos e Condições',
      redirect_url: null,
    })
  )
  return runWhen(ternaryCondition, desktopTagging, mobileTagging)
}
