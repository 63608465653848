import { useReducer } from "react"

interface IState {
  forFaq: boolean;
  forVideo: boolean;
  isVisibile: boolean;
}

interface IAction {
  type: 'SHOW_FAQ' | 'SHOW_VIDEO' | 'HIDE';
}

const pageSliderReducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case 'SHOW_FAQ':
      return {
        forFaq: true,
        forVideo: false,
        isVisibile: true,
      }
    case 'SHOW_VIDEO':
      return {
        forFaq: false,
        forVideo: true,
        isVisibile: true,
      }
    case 'HIDE':
      return {
        forFaq: false,
        forVideo: false,
        isVisibile: false,
      }
    default:
      return state
  }
}

const initialState = {
  forFaq: false,
  forVideo: false,
  isVisibile: false,
}

export const usePageSliderReducer = () => {
  const [ state, dispatch ] = useReducer(pageSliderReducer, initialState)
  return {
    pageSliderState: state,
    openVideo: () => dispatch({ type: 'SHOW_VIDEO' }),
    openFaq: () => dispatch({ type: 'SHOW_FAQ' }),
    closePageSlider: () => dispatch({ type: 'HIDE' }),
  }
}
