import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const CardWithButton = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  border-radius: 0.75rem;
  background-color: #F6F6F5;
  grid-template-rows: 22px minmax(62px, 1fr) 16px;

  .card__icon {
    height: 22px;
    width: 22px;
  }

  .card__link,
  .card__title {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .card__title {
    color: #1E1A19;
    align-self: end;
    margin-bottom: 0.5rem;
  }

  .card__button {
    color: ${primary[500]};
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    svg {
      height: 16px;
      width: 16px;
    }
  }
`
