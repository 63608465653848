/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react'
import * as S from './styles'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { gray } from 'src/styles/newColors'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'
import { useInterBridgeInstance } from '../../hooks/_useInterBridgeInstance'

import { FLUXO_TAG, PRODUCT_NAME } from '../../_constants'
import useDeviceDetection from 'src/hooks/useDeviceDetection/useDeviceDetection'
import { runWhen } from 'src/shared/runWhen'

type Investment = {
  title: string;
  body: string;
  detailsList: {
    title: string;
    body: string;
    icon: string;
  }[];
  ctaText: string;
  ctaLink: string;
  adjustLink: string;
}

type InvestmentsDisplayProps = {
  investment: Investment;
}

const InvestmentsDisplay: React.FC<InvestmentsDisplayProps> = ({ investment }: InvestmentsDisplayProps) => {
  const iWb = useInterBridgeInstance()
  const { isMobile }: ReturnType<typeof useDeviceDetection> = useDeviceDetection()
  const isTesouroMePoupeWeb = process.env.IS_TESOURO_ME_POUPE_WEB === 'true'
  useEffect(() => {
    initTheme(Theme.PF)
  })

  const handleClick = async (investment: Investment) => {
    runWhen(isMobile, async () => {
      await iWb?.interWbNavigate.openDeepLink(investment.ctaLink)
      await iWb?.interWbSession.requestAnalytics(FLUXO_TAG, {
        screen: 'Construa seu Tesouro',
        ref_type: 'botao',
        content_action: 'Toque',
        action_id: investment.ctaLink,
        adjust_event_token: '0',
        product: PRODUCT_NAME,
      })
    }, () => { return null })
  }

  return (
    <S.InvestmentsDisplay>
      <h1 className='investments__title'>{investment.title}</h1>
      <p className='investments__body'>{investment.body}</p>
      <h2 className='investments-list__title'>Você pode usar para:</h2>
      <ul className='investments-list'>
        {investment.detailsList.map((item: { title: string; body: string; icon: string }, index: number) => (
          <li key={index} className='investments-list__item'>
            <IconsSwitch lib='interco' icon={item.icon} size='LG' color={gray[500]} />
            <div>
              <h3 className='investments-list__item__title'>{item.title}</h3>
              <p className='investments-list__item__body'>{item.body}</p>
            </div>
          </li>
        ))}
      </ul>
      {isTesouroMePoupeWeb && (
        <a className='investments__link' onClick={() => handleClick(investment)} href={investment.adjustLink}>
          <Button
            fullWidth
            type='button'
          >
            {investment.ctaText}
          </Button>
        </a>
      )}
      {!isTesouroMePoupeWeb && (
        <a className='investments__link' onClick={() => handleClick(investment)}>
          <Button
            fullWidth
            type='button'
          >
            {investment.ctaText}
          </Button>
        </a>
      )}

    </S.InvestmentsDisplay>
  )
}

export default InvestmentsDisplay
